import React from "react"
import { graphql } from "gatsby"
import Panel from "../components/panel"
import Card from "../components/card"
import Banner from "../components/banner"
import Engpro from "../components/engpro"
import CarolAssistant from "../components/carolAssistant/avatar"
import { Carousel } from "react-responsive-carousel"
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

const getResizedImg = (node, size) => {
    if (node.frontmatter === undefined) return '';
    let srcSet = node.frontmatter.featuredImage.childImageSharp.fluid.srcSet;
    console.log(srcSet);
    console.log(size);
    let imgPath = srcSet.split(',').find(elem => elem.indexOf(size.toString()) !== -1).trim().split(' ')[0] || ""; 
    return imgPath || '';
}

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

export default ({ data }) => {
    const banner = data.banner.nodes[0];
    const country = banner.fileAbsolutePath.split('localized/')[1].split('/')[0]
    const language = banner.fileAbsolutePath.split('localized/')[1].split('/')[1]
    console.log(country, language);
    let readMore;
    let newsHeader;
    if (language === 'por') {
        readMore = 'Saiba mais';
        newsHeader = 'Comunicados Importantes'
    } else if (language === 'eng') {
        readMore = 'Read more';
        newsHeader = 'Important News'
    } else if (language === 'spa') {
        readMore = 'Sepa mas';
        newsHeader = 'Anuncios Importantes'
    } 

    const cards = data.cards.nodes;
    const standardCards = data.standardCards.nodes;
    const links = data.links.nodes;
    const news = data.news.nodes;

    const engproConfig = data.engproConfig.nodes[0];
    const engproBannerFilters = data.engproBannerFilters.nodes[0];
    let moduleName;
    if (engproBannerFilters) moduleName = engproBannerFilters.frontmatter.name; 
    const engproBanner = data.engproBanner.nodes;
    const engproTwinCards = data.engproTwinCards.nodes;
    const engproTripleCards = data.engproTripleCards.nodes;
    
    console.log(moduleName);
    let bannerFilter = engproBanner.filter(engproItem => {
        return (engproItem.frontmatter.showin && engproItem.frontmatter.showin.filter(x => x === moduleName).length > 0) || (engproItem.frontmatter.showin === null || (engproItem.frontmatter.showin && engproItem.frontmatter.showin.length === 0));
    });

    //Getting all assets from markdown
    const assetBanner = data.assetBanner.nodes[0];
    const assetHeader = data.assetHeader.nodes[0];
    const assetCardBlue = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-blue')[0];
    const assetCardGray = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-gray')[0];
    const assetCardOrange = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-orange')[0];
    const assetCardBlueSmall = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-blue-small')[0];
    const assetCardGraySmall = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-gray-small')[0];
    const assetCardOrangeSmall = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-orange-small')[0];

    //Getting all asset img paths
    let groupedTwinCards;
    let groupedTripleCards;
    
    if (engproConfig.frontmatter.blocks === 2) {
        groupedTwinCards = Array.from(groupBy(engproTwinCards, (item) => {
            if (item.frontmatter.group) return item.frontmatter.group
            else return ''
        }));
    } else if (engproConfig.frontmatter.blocks === 3) {
        groupedTripleCards = Array.from(groupBy(engproTripleCards, (item) => {
            if (item.frontmatter.group) return item.frontmatter.group
            else return ''
        }));
    } 

    const bannerImg = getResizedImg(assetBanner, 1024);
    const headerImg = getResizedImg(assetHeader, 1024);
    const cardBlueImg = getResizedImg(assetCardBlue, 320);
    const cardGrayImg = getResizedImg(assetCardGray, 320);
    const cardOrangeImg = getResizedImg(assetCardOrange, 320);
    const cardBlueImgSmall = getResizedImg(assetCardBlueSmall, 320);
    const cardGrayImgSmall = getResizedImg(assetCardGraySmall, 320);
    const cardOrangeImgSmall = getResizedImg(assetCardOrangeSmall, 320);


    //Getting all card content from markdown (standard)
    const standardCardTopBlue = standardCards.filter(x => x.frontmatter.type === 'card-top-blue')[0];
    const standardCardTopGray = standardCards.filter(x => x.frontmatter.type === 'card-top-gray')[0];
    const standardCardTopOrange = standardCards.filter(x => x.frontmatter.type === 'card-top-orange')[0];
    const standardCardBottomBlue = standardCards.filter(x => x.frontmatter.type === 'card-bottom-blue')[0];
    const standardCardBottomGray = standardCards.filter(x => x.frontmatter.type === 'card-bottom-gray')[0];
    const standardCardBottomOrange = standardCards.filter(x => x.frontmatter.type === 'card-bottom-orange')[0];

    //Getting all card content from markdown (localized)
    const cardTopBlue = cards.filter(x => x.frontmatter.type === 'card-top-blue')[0];
    const cardTopGray = cards.filter(x => x.frontmatter.type === 'card-top-gray')[0];
    const cardTopOrange = cards.filter(x => x.frontmatter.type === 'card-top-orange')[0];
    const cardBottomBlue = cards.filter(x => x.frontmatter.type === 'card-bottom-blue')[0];
    const cardBottomGray = cards.filter(x => x.frontmatter.type === 'card-bottom-gray')[0];
    const cardBottomOrange = cards.filter(x => x.frontmatter.type === 'card-bottom-orange')[0];
    
    const groupedLinks = Array.from(groupBy(links, (item) => {
        if (item.frontmatter.category) return item.frontmatter.category
        else return ''
    }));
    //groupedLinks.sort(compareObjGroups);

    const horizontalContainerConditionalJustifyTop = (cardTopBlue && cardTopGray && cardTopOrange) ? {"justify-content": "space-between"} : {"justify-content": "space-around"};
    const horizontalContainerConditionalJustifyBottom = (cardBottomBlue && cardBottomGray && cardBottomOrange) ? {"justify-content": "space-between"} : {"justify-content": "space-around"};

    return (
        <div className="main">
            <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"   rel="stylesheet"></link>
            {banner && <Banner title={banner.frontmatter.title} imgPath={bannerImg}>
                {banner.frontmatter.text}
            </Banner>}
            {standardCards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyTop}>
                {standardCardTopGray && <Card title={standardCardTopGray.frontmatter.title} color="gray" link={standardCardTopGray.frontmatter.link} imgPath={cardGrayImgSmall} cardTop={true}></Card>}
                {standardCardTopBlue && <Card title={standardCardTopBlue.frontmatter.title} color="blue" link={standardCardTopBlue.frontmatter.link} imgPath={cardBlueImgSmall} cardTop={true}></Card>}
                {standardCardTopOrange && <Card title={standardCardTopOrange.frontmatter.title} color="orange" link={standardCardTopOrange.frontmatter.link} imgPath={cardOrangeImgSmall} cardTop={true}></Card>}
            </div>}
            {cards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyTop}>
                {cardTopGray && <Card title={cardTopGray.frontmatter.title} color="gray" link={cardTopGray.frontmatter.link} imgPath={cardGrayImgSmall} cardTop={true}></Card>}
                {cardTopBlue && <Card title={cardTopBlue.frontmatter.title} color="blue" link={cardTopBlue.frontmatter.link} imgPath={cardBlueImgSmall} cardTop={true}></Card>}
                {cardTopOrange && <Card title={cardTopOrange.frontmatter.title} color="orange" link={cardTopOrange.frontmatter.link} imgPath={cardOrangeImgSmall} cardTop={true}></Card>}
            </div>}
            {
              engproConfig.frontmatter.blocks === 1 && !engproConfig.frontmatter.disable &&
                <div className="engproArea">
                    <Carousel 
                        showThumbs={false} 
                        infiniteLoop={true} 
                        showIndicators={true} 
                        showStatus={false} 
                        autoPlay={true}
                        interval={5000}
                    >
                    {bannerFilter.map(engproItem => {
                        let engproBannerImg = (engproBanner !== undefined) ? getResizedImg(engproItem, engproItem.frontmatter.size) : undefined;
                        console.log(engproItem)
                        return (
                            <Engpro imgPath={engproBannerImg} title={engproItem.frontmatter.title} link={engproItem.frontmatter.link} width={engproItem.frontmatter.size} height={engproItem.frontmatter.height || "200"}>
                                <div dangerouslySetInnerHTML={{__html: engproItem.html}}></div>
                            </Engpro>
                        )
                    })}
                    </Carousel>
                </div>
            }
            {
              engproConfig.frontmatter.blocks === 2 && !engproConfig.frontmatter.disable &&
                <div className="engproArea">
                    <Carousel 
                        showThumbs={false} 
                        infiniteLoop={true} 
                        showIndicators={false} 
                        showStatus={false} 
                        autoPlay={true}
                    >
                    {groupedTwinCards.map(twinCardItem => {
                        let twinCards = twinCardItem[1];
                        let engproTwinFirstImg = (twinCards[0] !== undefined) ? getResizedImg(twinCards[0], 500) : undefined;
                        let engproTwinSecondImg = (twinCards[1] !== undefined) ? getResizedImg(twinCards[1], 500) : undefined

                        return (
                            <div className="horizontal-container">
                                <Engpro imgPath={engproTwinFirstImg} title={twinCards[0].frontmatter.title} link={twinCards[0].frontmatter.link} width="500">
                                    <div dangerouslySetInnerHTML={{__html: twinCards[0].html}}></div>
                                </Engpro>
                                <Engpro imgPath={engproTwinSecondImg} title={twinCards[1].frontmatter.title} link={twinCards[1].frontmatter.link} width="500"> 
                                    <div dangerouslySetInnerHTML={{__html: twinCards[1].html}}></div>
                                </Engpro>
                            </div>
                        )
                    })}
                    </Carousel>
                </div>
            }
            {
              engproConfig.frontmatter.blocks === 3 && !engproConfig.frontmatter.disable &&
                <div className="engproArea">
                    <Carousel 
                        showThumbs={false} 
                        infiniteLoop={true} 
                        showIndicators={false} 
                        showStatus={false} 
                        autoPlay={true}
                    >
                    {groupedTripleCards.map(tripleCardItem => {
                        let tripleCards = tripleCardItem[1];
                        let engproTripleFirstImg = (tripleCards[0] !== undefined) ? getResizedImg(tripleCards[0], 320) : undefined;
                        let engproTripleSecondImg = (tripleCards[1] !== undefined) ? getResizedImg(tripleCards[1], 320) : undefined;
                        let engproTripleThirdImg = (tripleCards[2] !== undefined) ? getResizedImg(tripleCards[2], 320) : undefined;

                        return (
                            <div className="horizontal-container">
                                <Engpro imgPath={engproTripleFirstImg} title={tripleCards[0].frontmatter.title} link={tripleCards[0].frontmatter.link} width="320" height="200">
                                    <div dangerouslySetInnerHTML={{__html: tripleCards[0].html}}></div>
                                </Engpro>
                                <Engpro imgPath={engproTripleSecondImg} title={tripleCards[1].frontmatter.title} link={tripleCards[1].frontmatter.link} width="320" height="200">
                                    <div dangerouslySetInnerHTML={{__html: tripleCards[1].html}}></div>
                                </Engpro>
                                <Engpro imgPath={engproTripleThirdImg} title={tripleCards[2].frontmatter.title} link={tripleCards[2].frontmatter.link} width="320" height="200">
                                    <div dangerouslySetInnerHTML={{__html: tripleCards[2].html}}></div>
                                </Engpro>
                            </div>
                        )
                    })}
                    </Carousel>
                </div>
            }

            {news.length > 0 && <Panel title={newsHeader} imgPath={headerImg}>
                <ul>
                {news.map(newsItem => (
                    <li key={`key${news.indexOf(newsItem)}`}>
                        <div>
                            <b>{newsItem.frontmatter.title}</b>
                            <p>{newsItem.frontmatter.text}
                                {newsItem.frontmatter.link && <a target="_placeholder" rel="noopener noreferrer" href={newsItem.frontmatter.link}>
                                    <u>{readMore}</u>
                                    <i className="material-icons">link</i>
                                </a>}
                            </p>
                        </div>
                    </li>
                ))}
                </ul>
            </Panel>}
            {standardCards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyBottom}>
                {standardCardBottomGray && <Card title={standardCardBottomGray.frontmatter.title} color="gray" link={standardCardBottomGray.frontmatter.link} imgPath={cardGrayImg}>
                    <p>{standardCardBottomGray.frontmatter.text}</p>
                </Card>}
                {standardCardBottomBlue && <Card title={standardCardBottomBlue.frontmatter.title} color="blue" link={standardCardBottomBlue.frontmatter.link} imgPath={cardBlueImg}>
                    <p>{standardCardBottomBlue.frontmatter.text}</p>
                </Card>}
                {standardCardBottomOrange && <Card title={standardCardBottomOrange.frontmatter.title} color="orange" link={standardCardBottomOrange.frontmatter.link} imgPath={cardOrangeImg}>
                    <p>{standardCardBottomOrange.frontmatter.text}</p>
                </Card>}
            </div>}
            {cards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyBottom}>
                {cardBottomGray && <Card title={cardBottomGray.frontmatter.title} color="gray" link={cardBottomGray.frontmatter.link} imgPath={cardGrayImg}>
                    <p>{cardBottomGray.frontmatter.text}</p>
                </Card>}
                {cardBottomBlue && <Card title={cardBottomBlue.frontmatter.title} color="blue" link={cardBottomBlue.frontmatter.link} imgPath={cardBlueImg}>
                    <p>{cardBottomBlue.frontmatter.text}</p>
                </Card>}
                {cardBottomOrange && <Card title={cardBottomOrange.frontmatter.title} color="orange" link={cardBottomOrange.frontmatter.link} imgPath={cardOrangeImg}>
                    <p>{cardBottomOrange.frontmatter.text}</p>
                </Card>}
            </div>}
            {links.length > 0 && <Panel title="Links" imgPath={headerImg}>
                {groupedLinks.length > 0 && groupedLinks.map(group => {
                    if (group[0] !== '') {
                        return (
                            <div key={`key${getRandomInt(0,10000)}`}>
                                <b>{group[0]}</b>
                                <ul>
                                    {group[1].map(link => (
                                        <li key={`key${getRandomInt(0,10000)}`}>
                                            {link.frontmatter.text}
                                            {link.frontmatter.link && <a target="_placeholder" rel="noopener noreferrer" href={link.frontmatter.link}>
                                                <u>{readMore}</u>
                                                <i className="material-icons">link</i>
                                            </a>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                    } else {
                        return (
                            <ul>
                                {group[1].map(link => (
                                <li key={`key${getRandomInt(0,10000)}`}>
                                    {link.frontmatter.text}
                                    <a target="_placeholder" rel="noopener noreferrer" href={link.frontmatter.link}>
                                        <u>{readMore}</u>
                                        <i className="material-icons">link</i>
                                    </a>
                                </li>
                                ))}
                            </ul>
                        )
                    }
                }
            )}</Panel>}
            <CarolAssistant></CarolAssistant>
        </div>
    )
}

export const query = graphql`
query ($parentPath: String!, $standardPath: String!, $parentAndStandardPath: String!, $languageRegex: String!, $moduleNameRegex: String!) {
    engproConfig: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro-config/"}}) {
        nodes {
            html
            excerpt
            frontmatter {
                blocks
                disable
            }
        }
    }

    engproBannerFilters: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro/module-filters/"}, frontmatter: { name: {regex: $moduleNameRegex}}}) {
        nodes {
            html
            excerpt
            frontmatter {
                name
            }
        }
    }

    engproBanner: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro/"}, frontmatter: { type: {regex: "/banner/"}, language: {regex: $languageRegex}}}, sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
            html
            excerpt
            frontmatter {
                title
                type
                size
                height
                link
                language
                showin
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    engproTwinCards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro/"}, frontmatter: { type: {regex: "/twin/"}, language: {regex: $languageRegex}}}) {
        nodes {
            html
            excerpt
            frontmatter {
                group
                title
                type
                size
                height
                link
                language
                showin
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    engproTripleCards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro/"}, frontmatter: { type: {regex: "/triple/"}, language: {regex: $languageRegex}}}) {
        nodes {
            html
            excerpt
            frontmatter {
                group
                title
                type
                size
                height
                link
                language
                showin
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 320, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetCountdown: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "countdown"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 95) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetCards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {regex: "/card/"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 320, quality: 95) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetBanner: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "banner"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "panel-header"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    banner: allMarkdownRemark(filter: { fileAbsolutePath: {regex: $parentPath}, frontmatter: { type: {eq: "banner"}}} ) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              type
              text
            }
            fileAbsolutePath  
        }
    },
    cards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: $parentPath}, frontmatter: { type: {regex: "/card/"}}}) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              type
              text
              link
            }  
        }
    },
    standardCards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: $standardPath}, frontmatter: { type: {regex: "/card/"}}}) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              type
              text
              link
            }  
        }
    },
    links: allMarkdownRemark(filter: {fileAbsolutePath: {regex: $parentAndStandardPath}, frontmatter: { type: {regex: "/link/"}}}, sort: { fields: frontmatter___date, order: DESC }) {
        nodes{
            html
            excerpt
            frontmatter {
              link
              type
              text
              link
              category
              date
            }  
        }
    },
    news: allMarkdownRemark(filter: {fileAbsolutePath: {regex: $parentAndStandardPath}, frontmatter: { type: {eq: "news"}}}, sort: { fields: frontmatter___date, order: DESC }) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              text
              type
              link
              date
            }  
        }
    }
  }
`