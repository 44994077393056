import React from "react"
import Avatar from "./Avatar.png"
import CloseImg from "./close.png"
import { css } from "@emotion/core"

const avatarCss = css`
        z-index: 2147483647;
        position: fixed;
        right: 50px;
        bottom: 50px;
        font-family: "Inter", sans-serif;
        width: 80px;
        height: 80px;
        box-sizing: initial;
        border-radius: 50%;
        display: none;
    `
const messageCss = css`
        display: none;
        align-items: center;
        width: 380px;
        height: 55px;
        top: -210px;
        border-radius: 4px;
        position: relative;
        border: 4px solid rgb(80, 56, 190);
        padding: 16px;
        background: white none repeat scroll 0% 0%;
        right: 426px;
        font-size: 18px;
        line-height: 20px;
        color: rgb(86, 98, 110);
        text-align: left;
`

const borderCss = css`
        width: 0px;
        height: 0px; 
        right: 0px; 
        top: 60px; 
        position: absolute; 
        border-style: solid; 
        border-width: 0px 0px 30px 30px; 
        border-color: transparent transparent rgb(80, 56, 190);
`

const closeCss = css`
        position: absolute;
        background-image: url(${CloseImg});
        width: 24px;
        height: 24px;
        background-size: contain;
        right: 8px;
        top: 8px;
        cursor: pointer;
`

function closeAvatarMessage() {
    var avatarMessage = document.querySelector(".avatarMessage");
    avatarMessage.style.display = "none";
}

function showAvatarMessage() {

    setTimeout(() => {
        var avatarDiv = document.querySelector(".avatarDiv");
        if (avatarDiv) avatarDiv.style.display = "block";
    }, 2000);

    setTimeout(() => {
        var avatarMessage = document.querySelector(".avatarMessage");
        if (avatarMessage) avatarMessage.style.display = "flex";
    }, 4000);
}

class AvatarCarolComponent extends React.Component {
    render() {
        return (
            <div>
                <div className="avatarDiv" css={avatarCss}>
                    <a target="_placeholder" href="https://totvs.carol.ai/protheusassistant/apps/dev/protheusassistant/1.0.0/index.html" rel="noreferrer">
                        <img src={Avatar} alt="Carolina Avatar" />
                    </a>
                    <div className="avatarMessage" css={messageCss} hidden>
                        <p style={{ margin: "22px" }}>Olá, sou a Carolina, assistente virtual da TOTVS.</p>
                        <div css={closeCss} onClick={closeAvatarMessage}></div>
                        <div css={borderCss}></div>
                    </div>
                    <script>
                        {showAvatarMessage()}
                    </script>
                </div>
            </div>
        )
    }
}

export default AvatarCarolComponent;