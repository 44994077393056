import React from "react"
import panelStyles from "./panel.module.css"
import { css } from "@emotion/core"

export default (props) => {

    const panelHeaderCss = css`
        background-image: url(${props.imgPath});
    `

    return (
        <div className={panelStyles.panel} style={props.style}>
            <div className={panelStyles.panelHeader} css={panelHeaderCss}>
                <b>{props.title}</b>
            </div>
            <div className={panelStyles.panelContent}>
                {props.children}
            </div>
        </div>
    )
}