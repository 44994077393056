import React from "react"
import mainStyles from "./engpro.module.css"
import { css } from "@emotion/core"

export default (props) => {

    const mainCss = css`
        background-image: url(${props.imgPath});
        min-height: ${props.height}px;
        width: ${props.width}px;
    `

    const linkCss = css`
        text-decoration: none;
        align-self: center;
        display: flex;
        justify-content: center;
        width: 1024px
    `

    return (
        <a target="_placeholder" rel="noopener noreferrer" href={props.link} css={linkCss}>
            <div className={mainStyles.main} style={props.style} css={mainCss}>
                <h1 className={mainStyles.mainHeader}>{props.title}</h1>
                <div className={mainStyles.mainContent}>
                    {props.children}
                </div>
            </div>
        </a>
    )
}