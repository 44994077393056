import React from "react"
import cardStyles from "./card.module.css"

import { css } from "@emotion/core"

export default (props) => {
    const cardCss = css`
        background-image: url(${props.imgPath});
        height: ${(props.cardTop) ? "103px" : "180px"};
        display: flex;
        flex-direction: column;
        justify-content: ${(props.cardTop) ? "center" : "auto"};
    `
    const linkCss = css`
        height: ${(props.cardTop) ? "103px" : "180px"};
        text-decoration: none;
    `
    const headerCss = css`
    `

    return (
        <a target="_placeholder" rel="noopener noreferrer" href={props.link} css={linkCss}>
            <div className={`${cardStyles.card} ${cardStyles[props.color]}`} css={cardCss}>
                <h2 className={cardStyles.cardHeader} css={headerCss}>{props.title}</h2>
                {props.children && <div className={cardStyles.cardContent}>
                    {props.children}
                </div>}
            </div>
        </a>
    )
}