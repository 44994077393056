import React from "react"
import bannerStyles from "./banner.module.css"
import { css } from "@emotion/core"


export default (props) => {
    const bannerCss = css`
        background-image: url(${props.imgPath});
    `

    return (
        <div className={bannerStyles.banner} css={bannerCss} style={props.style}>       
            <h1 className={bannerStyles.bannerHeader}>{props.title}</h1>
        </div>
    )
}


